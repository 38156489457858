import { Link } from 'react-router-dom';

import Model from './Model';

import btnIcon from '../images/btn-icon.svg'
import arIcon from "../images/ar-icon-cirlce.svg";
import webIcon from "../images/web-icon.svg";

function StackBlocks ({ 
    title, 
    text,
    desktopIcon,
    id,
    blockClassName,
    modelSrc,
    modelPoster,
    modelIos,
    hotspotPos,
    hotspotNorm,
    hotspotText
}) {
    return (
        <div className={blockClassName}>
        <div className="container">
            <div className="ar-block__image-group">
                <Model
                    source={modelSrc} 
                    poster={modelPoster} 
                    ios={modelIos} 
                    hotspotPos={hotspotPos}
                    hotspotNorm={hotspotNorm}
                    hotspotText={hotspotText}
                />
                {/* <img src={ arIcon } alt="" className="ar-block__ar-popup-image" />
                <img src={ webIcon } alt="" className="ar-block__web-icon" /> */}
            </div>
        </div>
        <div className="outter-container">
            <div className="container">
                <div className="ar-block__content ar-block__content--stat">
                    <h3 className="ar-block__title">
                        { [...title] }
                    </h3>
                    <p className="ar-block__text">
                        {text}
                    </p>
                    {/* <Link to="/" className="btn btn--yellow">
                        <img style={{
                            marginRight: '13px'
                        }} src={ btnIcon } alt="" />
                        Take a closer look
                    </Link> */}
                    <img className="ar-block__desktop-icon" src={ desktopIcon } style={{
                        bottom: (id == 4) && '-71px'
                    }} />
                </div>
            </div>
        </div>
    </div>
    ) 
}
export default StackBlocks;