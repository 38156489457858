import React, { useState } from 'react';
import { ExternalLink } from "react-external-link";

import './scss/Header.scss';

function Header () {

    const [headerClassName, setHeaderClassName] = useState('');

    const handleScroll = (headerClassName) => {
        if (headerClassName !== 'is_stuck' && window.pageYOffset >= 10) {
            setHeaderClassName('is_stuck');
        } else if (headerClassName === 'is_stuck' && window.pageYOffset < 10) {
            setHeaderClassName('');
        }
    }

    React.useEffect(() => {
        window.onscroll = () => handleScroll(headerClassName);
    }, [headerClassName]);


    return (
        <header id="header_menu" className={headerClassName}>
            <div className='container'>
                <ExternalLink href='https://www.mcdonalds.com/gb/en-gb/our-plan-for-change.html'>
                    McDonald's
                </ExternalLink>
            </div>
        </header>

    )
}

export default Header;