import {Route, Switch} from 'react-router-dom';

import Layout from './components/layout/Layout';

import Home from './views/Home';
import GreatFood from './views/GreatFood';
import GreatRestaurants from './views/GreatRestaurants';
import PeoplePositive from './views/PeoplePositive';
import PlanetPositive from './views/PlanetPositive';
import PlanForChange from './views/PlanForChange';
import ServingOurCommunities from './views/ServingOurCommunities';


import './App.scss';

function App() {
  return (
      <Layout>
        <Switch>
          <Route path='/' exact >
            <Home />
          </Route>
        </Switch>
      </Layout>
  )
}

export default App;