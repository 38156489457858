const blockData = [
    {
        id: 0,
        type: 'block',
        heading: ['And discover how our small', <br />,  <strong className="yellow">changes will make a big difference.</strong> ],
        headingClassName: 'main-heading',
        subHeading: <span>&nbsp;</span>,
        blockClassName: 'ar-block ar-block--reverse-rows',
        title: [
            'Feel the winds of ', <br /> ,
            'change – our onsite ', <strong className="yellow">wind turbines</strong> ,' produce ', '100% renewable electricity.', <br /> ,
        ],
        text: "The power we generate supports the 100% renewable energy we buy for our UK and Irish restaurants",
        link: "/planet-positive",
        modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Turbine.glb",
        modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/turbine.webp",
        modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Turbine.usdzz",
        hotspotPos: "-0.18290728847004134m 0.5978359703847554m 0.12271790982893892m",
        hotspotNorm: "0.9832294111403774m 0.18237303821712947m -1.7685628662821652e-8m",
        hotspotText: "Wind turbines provide onsite generation of renewable electricity, supporting the 100%  renewable electricity purchased for our UK & Irish restaurants."
    },
    {
        id: 1,
        type: 'block',
        heading: ['Dining with ', <span className="yellow">the family?</span>],
        headingClassName: 'main-heading',
        subHeading: <span>&nbsp;</span>,
        blockClassName: 'ar-block',
        title: [
            'Take a seat on our sturdy new ', <strong className="yellow">timber benches </strong>, <br /> ,
            'made in the ', <strong className="yellow">UK</strong>
        ],
        text: "The wood is FSC-certified so we’re planning for their future.",
        link: "/planet-positive",
        modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Booth Bench.glb",
        modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/boothbench.webp",
        modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Booth Bench.usdz",
        hotspotPos: "-0.18290728847004134m 0.5978359703847554m 0.12271790982893892m",
        hotspotNorm: "0.9832294111403774m 0.18237303821712947m -1.7685628662821652e-8m",
        hotspotText: "Manufactured in the UK from sustainably sourced ply wood. Print applied directly to wood with solvent-free ink."
    },
    {
        id: 2,
        type: 'stack-block',
        heading: ['Our big ', <span className="yellow">wall letters</span>],
        headingClassName: 'main-heading main-heading--sm',
        blockClassName: 'ar-block ar-block--stack',
        title: [
            'They’re made out of ',
            <br/>,
            'our ',
            <span className="yellow">coffee beans</span>
        ],
        text: "So your coffee bean waste can now lead to more than just great coffee.",
        link: "/planet-positive",
        modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Wall Lettering.glb",
        modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Wall Lettering.webp",
        modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Wall Lettering.usdz",
        hotspotPos: "-0.19274922055288776m 0.542106846759307m 0.031099468758421088m",
        hotspotNorm: "-7.255684478452971e-9m -2.1557151198860132e-7m 0.9999999999999767m",
        hotspotText: "Made from recyclable acrylic backing and our own coffee bean waste."
    },
    {
        id: 3,
        type: 'block',
        heading: ['See the ', <span className="yellow">wall signs?</span>,],
        headingClassName: 'main-heading main-heading--sm',
        blockClassName: 'ar-block ar-block--reverse-rows',
        title: [
            'They’re now made from', <br /> ,
            <strong className="yellow">100% recycled plastic</strong>,
        ],
        text: ['And they can be recycled again,', <br/> , 'when we need to change them.'],
        link: "/planet-positive",
        modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Signage.glb",
        modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Signage.webp",
        modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Signage.usdz",
        hotspotPos: "-0.38835729048935885m 0.41277266354197073m 0.006199999712407589m",
        hotspotNorm: "0m 0m 1m",
        hotspotText: "Made from recycled materials. Fully recyclable."
    },
    {
        id: 4,
        type: 'stack-block',
        hi: ' ',
        heading: ['One step ', <span className="yellow">at a time</span>],
        headingClassName: 'main-heading',
        //subHeading: ['Opening December 2021', <br /> , 'In Market Drayton, Shropshire'],
        subHeading: ' ',
        desktopStack: true,
        blockClassName: 'ar-block ar-block--stack',
        title: [
            'Our kerb stones are made',
            <br/>,
            'from ',
            <span className="yellow">100% recycled plastic bottles</span>
        ],
        text: ['So we’re putting used plastic to good use.'],
        link: "/planet-positive",
        modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/ConcreteKerbs.glb",
        modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/ConcreteKerbs.webp",
        modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/ConcreteKerbs.usdz",
        hotspotPos: "-0.3948790037734672m 0.20844179015414765m 0.05147833227952344m",
        hotspotNorm: "-3.084458377902927e-7m 0.25408627676206375m 0.967181556876007m",
        hotspotText: "Made from 100% recycled plastic. Reduces carbon emissions by 25kg per kerb compared to concrete kerbs."
    },
];

export default blockData;