import { useState } from 'react';

import './scss/Model.scss'; 

import aricon from './assets/ar-icon.svg';

function    Model(props) {

    const [showAnnotation, setShowAnnotation] = useState(true);

    const annotationHandler = () => {
        if (showAnnotation === true) {
            setShowAnnotation(false);
        } else {
            setShowAnnotation(true);
        }        
    }

    return(
        <model-viewer 
            classname='model-viewer' 
            src={props.source}
            ios-src={props.ios}        
            ar-modes="webxr scene-viewer quick-look"           
            environment-image="neutral" 
            poster={props.poster}
            shadow-intensity="1" 
            auto-rotate
            camera-controls 
            ar             
            >

            <button onClick={annotationHandler} class="Hotspot" slot="hotspot-1" data-position={props.hotspotPos} data-normal={props.hotspotNorm} data-visibility-attribute="visible">
            {
                showAnnotation &&
                <div class="HotspotAnnotation">{props.hotspotText}</div>
            }
            </button>

            {props.hotspotPos2 &&
                <button onClick={annotationHandler} class="Hotspot" slot="hotspot-2" data-position={props.hotspotPos2} data-normal={props.hotspotNorm2} data-visibility-attribute="visible">
                    {
                        showAnnotation &&
                        <div class="HotspotAnnotation">{props.hotspotText2}</div>
                    }
                </button>
            }

            <div className="progress-bar hide" slot="progress-bar">
                <div className="update-bar"></div>
            </div>
            <button className='btn btn--primary' slot="ar-button" id="ar-button">
                <img src={aricon} alt='Bring to life' />
                Bring to life
            </button>
            <div id="ar-prompt">
                <img src="https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/ar_hand_prompt.png" />
            </div>
            <div className='center-pre-prompt'></div>
        </model-viewer>  
    );
}

export default Model;