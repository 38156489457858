import './scss/Hero.scss';

import mcdonaldslogo from './layout/assets/mcdonalds-logo.svg'

import image from './images/hero-mobile.png';
import arrowIcon from './images/arrow-icon.svg';

function Hero ({ text, titleMain, titleLight, titleBoldSm }) {

    const heroStyle = {
        backgroundImage: `url(${image})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat"
    };

    return (
        <section className='hero hero--mobile' style={heroStyle}>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <img style={{width: '50px',marginBottom: '50px'}} src={mcdonaldslogo} alt="McDonald's Logo" className='logo' />
                        <h1>
                            {titleMain}
                        </h1>
                        <p className="hero__sub-title">{titleLight}</p>
                        <p className="hero__cta-text" style={
                                    {
                                        marginBottom: "105px"
                                    }
                        }>
                            {
                                titleBoldSm && 
                                <strong>{titleBoldSm}</strong>
                            }
                        </p>

                        <img className="hero__arrow-icon" src={arrowIcon}/>
                    </div>
                 
                </div>
            </div>
        </section>
    )
}

export default Hero;