import { Fragment } from "react";
import Blocks from "./Blocks";
import StackBlocks from "./StackBlocks";

function BlocksContainer({ data, id }) {

    
    const sectionStyle = {
        background: '#fff',
        marginTop: '-400px',
        paddingTop: '400px'
    }

    const template = function(data, id) {
        if(data.type === 'block') {
            return (
                <section className={(id==1) ? 'grey-bg intro' : 'grey-bg'} style={ (id == 2) ? sectionStyle : {} }>
                    <div className="container">
                    <h2 className={ data.headingClassName } >{data.heading}</h2>
                        { data.subHeading && <p className={(id==1) ? 'sub-heading intro' : 'sub-heading'}>{ data.subHeading }</p>}               

                            <Blocks 
                                key={data.id}
                                id={data.id} 
                                title={data.title}
                                text={data.text} 
                                cols="block--margin col-12 col-lg-4"   
                                link={data.link}
                                yellow={data.yellow}
                                desktopStack={ data.desktopStack }
                                blockClassName={ data.blockClassName }
                                modelIos={data.modelIos}
                                modelSrc={data.modelSrc}
                                modelPoster={data.modelPoster}
                                hotspotPos={data.hotspotPos}
                                hotspotNorm={data.hotspotNorm}
                                hotspotText={data.hotspotText}
                            />                    
                    </div>
            </section>
            )
        }

        if(data.type === 'stack-block') {
            return(
                <section className={(id == 2) ? 'grey-bg no-pb-desktop' : 'grey-bg'} style={ (id == 2) ? sectionStyle : {} }>
                    <div className="container">
                        <h2 className={ data.headingClassName }>{data.heading}</h2>
                        { data.subHeading && <p className="sub-heading">{ data.subHeading }</p>}
                    </div>
                    <StackBlocks
                        key={data.id}
                        id={data.id} 
                        title={data.title}
                        text={data.text} 
                        cols="block--margin col-12 col-lg-4"   
                        link={data.link}
                        yellow={data.yellow}
                        desktopStack={ data.desktopStack }
                        blockClassName={ data.blockClassName }
                        modelIos={data.modelIos}
                        modelSrc={data.modelSrc}
                        modelPoster={data.modelPoster}
                        hotspotPos={data.hotspotPos}
                        hotspotNorm={data.hotspotNorm}
                        hotspotText={data.hotspotText}
                    />
            </section>
            )
        }
    }
    return(
        <Fragment>
            {
               template(data, id)
            }
        </Fragment>
    )
}

export default BlocksContainer;