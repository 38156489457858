import { Link } from 'react-router-dom';

import Model from './Model';

import btnIcon from '../images/btn-icon.svg'
import arIcon from "../images/ar-icon-cirlce.svg";
import webIcon from "../images/web-icon.svg";

function Blocks ({ 
    title, 
    text,
    blockClassName,
    modelSrc,
    modelPoster,
    modelIos,
    hotspotPos,
    hotspotNorm,
    hotspotText
}) {
    return (
        <div className={blockClassName}>
        <div className="ar-block__image-group">
            <Model
                source={modelSrc} 
                poster={modelPoster} 
                ios={modelIos} 
                hotspotPos={hotspotPos}
                hotspotNorm={hotspotNorm}
                hotspotText={hotspotText}
            />
            {/* <img src={ arIcon } alt="" className="ar-block__ar-popup-image" />
            <img src={ webIcon } alt="" className="ar-block__web-icon" /> */}
        </div>
        <div className="ar-block__content">
            <h3 className="ar-block__title">
                { [...title] }
            </h3>
            <p className="ar-block__text">
                {text}
            </p>
            {/* <Link to="/" className="btn btn--yellow">
                <img style={{
                    marginRight: '13px'
                }} src={ btnIcon } alt="" />
                Take a closer look
            </Link> */}
        </div>
    </div>
    ) 
}
export default Blocks;