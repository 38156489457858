import Blocks from "../components/Blocks";
import BlocksContainer from "../components/BlocksContainer";
import Hero from "../components/Hero";
import LargeHero from "../components/LargeHero";
import Model from "../components/Model";
import TextBlock from "../components/TextBlock";
import TilesContainer from "../components/TilesContainer";
import blockData from "../data/BlockData";


function Home () {
    return (
    <div>
    <LargeHero 
       key={1}
        //title={['Change ' , <br className='hide'/>, 'a little,', <br/>, 'change ',  <br className='hide'/>, 'a lot', <sup>&trade;</sup>]}
        //title={['Take' , <br />, 'a look...']}
        title={['Take a look...']}
       />
       {/* <LargeHero 
       key={1}
        titleMain={[
            "Change a little", <br/> ,"change a lot",
        ]}
        titleLight='See the little changes we’re making in our restaurants'
        //titleBoldSm={['Interact with our innovations below']}
       />

       
        <Hero 
        key={1}
            titleMain={[
                "Change a little", <br/>,"Change a lot",
            ]}
            titleLight={[
                'See the little changes we’re making in our restaurants.' 
            ]}
            //titleBoldSm={['Interact with our', <br/> , 'innovations below']}
       />    */}

     
        {
        blockData.map(data => {
            return(
                <BlocksContainer
                    data={data}
                    key={data.id}
                    id={data.id}
                />
            )
        })

        }
        <TilesContainer />
        <TextBlock />
        
    </div>

    )
}

export default Home;