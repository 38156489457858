import { Fragment } from "react";
import Tiles from './Tiles';
import tileData from "../data/TileDataSauchiehallStreet";

function TilesContainer() {
    const headingStyles = 
        {
            marginBottom: '70px',
            fontSize: '55px',
            lineHeight: '65px',
            textAlign: 'center'
            
        }
    return(
        <Fragment>
            <section className='grey-bg'>
                <div className="container">
                <h2 style={ headingStyles }>
                We're not done yet. <br />
                   <span className="yellow">
                         <span className="break">Experience more....</span>
                    </span>
                </h2>

                    <div className="row">
                     {
                         tileData.map(section => {
                             return(
                                <Fragment key={section.id}>
                                       <h3 className="tiles-heading">{section.heading}</h3>
                                        {
                                            (section.subHeading !== "" || section.subHeading) &&
                                            <p className="tiles-sub-heading">{ section.subHeading }</p>
                                        }
                                
                                   {
                                        section.data.map(data => {
                                            const { image, title, id } = data;
            
                                            return(
                                               <div className="col-6 col-md-3 col-lg-2 col-lg-2--five">
                                                    <Tiles
                                                        key={ id }
                                                        image={ image }
                                                        title={ title }
                                                        isAtSauchiehallStreet = {data.isAtSauchiehallStreet}
                                                        modelIos={data.modelIos}
                                                        modelSrc={data.modelSrc}
                                                        modelPoster={data.modelPoster}
                                                        hotspotPos={data.hotspotPos}
                                                        hotspotNorm={data.hotspotNorm}
                                                        hotspotText={data.hotspotText}
                                                        hotspotPos2={data.hotspotPos2}
                                                        hotspotNorm2={data.hotspotNorm2}
                                                        hotspotText2={data.hotspotText2}
                                                    />
                                               </div>
                                            )
                                        })
                                   }
                                
                                </Fragment>
                             )
                         })
                     }
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default TilesContainer;