import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';

import App from './App';


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop /> 
        <App />
    </BrowserRouter>,  
    document.getElementById('root')
);