import textBlockBg from '../images/text-block-bg.png';
import arrowIcon from './images/arrow-icon.svg';

import { ExternalLink } from 'react-external-link';

import './scss/TextBlock.scss'

function TextBlock () {
    return (
        <section className='grey-bg'>
            <div className='container'>
                <div className="row">
                    <div className="col">
                        <div className="text-block">
                            <div className="content">
                                <div className="copy">
                                    <h2 className='title'>Our Plan for <span className="yellow">Change</span></h2>
                                    <p>
                                        Learn more about the small changes we’re making that add up to something much bigger.
                                    </p>
                                    <p>          
                                        View our Plan for Change with goals and actions to help us lead positive change.
                                    </p>
                                </div>
                                <img className="desktop-img" src={textBlockBg} />
                            </div>
                            <ExternalLink href='https://information.togetherwecanchangealot-mcdonalds.co.uk/'>
                                <img className="arrow-icon" src={arrowIcon} />
                            </ExternalLink>                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TextBlock;