import { useState } from 'react';

import Model from './Model';

import closemodal from './assets/nav-close.svg';

import './scss/Tiles.scss';

function Tile({ image, title, modelSrc, modelPoster, modelIos, hotspotPos, hotspotNorm, hotspotText, hotspotPos2, hotspotNorm2, hotspotText2, isAtSauchiehallStreet }) {

    const [showAr, setShowAr] = useState(false);

    const showArHandler = () => {
        setShowAr(true);
    }

    const hideArHandler = () => {
        setShowAr(false);
    }

    return(
        <div>
            <div className="tile" onClick={showArHandler}>
                {
                    isAtSauchiehallStreet
                    &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-asterisk tile__icon yellow" viewBox="0 0 16 16">
                    <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
                    </svg>
                }
                <img className="tile__image" src={image}/>
                <h4 className="tile__title">{ title }</h4>
            </div>
            {
                showAr &&

                <div className='tile-ar'>
                    <div className='tile-ar__backdrop' onClick={hideArHandler}></div>
                    <img src={closemodal} className='close-ar-modal' alt="Close AR Modal" onClick={hideArHandler} />
                    <Model
                    source={modelSrc} 
                    poster={modelPoster} 
                    ios={modelIos}
                    hotspotPos={hotspotPos}
                    hotspotNorm={hotspotNorm}
                    hotspotText={hotspotText}
                    hotspotPos2={hotspotPos2}
                    hotspotNorm2={hotspotNorm2}
                    hotspotText2={hotspotText2}
                    />
                </div>
            }
            
        </div>

    );
} 

export default Tile;