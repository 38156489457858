const tileData = [
    {
        id: 2,
        heading: ['Innovations at your new', <br />, <span className="yellow">Market Drayton</span>, ' restaurant, which will be the UK\'s first', <br />, 'net zero carbon restaurant'],
        subHeading: [<span className="yellow">*</span>,  ' Also can be seen in Sauchiehall Street, Glasgow'],
        data: [
            {
                id: 1,
                title: 'Wooden window frames',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/windowframes.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/WindowFrames.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/windowframes.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/WindowFrames.usdz",
                hotspotPos: "-0.435229917606787m 1.0311377249239395m 0.03134431087597942m",
                hotspotNorm: "0m 0m 1m",
                hotspotText: "Less energy intensive to manufacture compared to standard aluminium frames. Fully recyclable."
            },
            {
                id: 2,
                title: 'Banners',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/banner.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Banner.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/banner.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Banner.usdz",
                hotspotPos: "-1.1778014552612914m 0.8947532375623497m 0.08996940351229421m",
                hotspotNorm: "-8.923815035157655e-8m 0m 0.9999999999999961m",
                hotspotText: "Banner made from 100% PVC-free material and fully recyclable."
            },
            // {
            //     id: 3,
            //     title: 'Building insulation',
            //     image: tileRenderImage,
            //     modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/BuildingInsulation.glb",
            //     modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/BuildingInsulation.webp",
            //     modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/BuildingInsulation.usdz"
            // },
            {
                id: 3,
                title: 'High chairs',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/highchair.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/HighChair.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/highchair.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/HighChair.usdz",
                hotspotPos: "0.18221488814905928m 0.7744596606200038m 0.14966960183834344m",
                hotspotNorm: "-0.08181560382081039m 0.5279313522036768m 0.8453370300252045m",
                hotspotText: "Made from sustainable wood and painted with solvent-free paint."
            },
            {
                id: 4,
                title: 'Kerb stones',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/concretekerbs.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/ConcreteKerbs.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/concretekerbs.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/ConcreteKerbs.usdz",
                hotspotPos: "-0.3948790037734672m 0.20844179015414765m 0.05147833227952344m",
                hotspotNorm: "-3.084458377902927e-7m 0.25408627676206375m 0.967181556876007m",
                hotspotText: "Made from 100% recycled plastic. Reduces carbon emissions by 25kg per kerb compared to concrete kerbs."
            },
            {
                id: 5,
                title: 'Hand dryers',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/handdryer.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/HandDryer.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/handdryer.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/HandDryer.usdz",
                hotspotPos: "0.01670991715927208m 0.1337042996356971m 0.04479353564725387m",
                hotspotNorm: "0.07853590172237788m -2.4885774266061657e-8m 0.9969112859932183m",
                hotspotText: "Highly energy efficient and provides air purification functions to improve air quality."
            },
            // {
            //     id: 6,
            //     title: 'Patio lighting',
            //     image: tileRenderImage,
            //     modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/PatioLighting_babylonEdited.glb",
            //     modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/PatioLighting_babylonEdited.webp",
            //     modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/PatioLighting_babylonEdited.usdz"
            // },
            // {
            //     id: 7,
            //     title: 'Table and parasol',
            //     image: tileRenderImage,
            //     modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/TableAndParasol.glb",
            //     modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/TableAndParasol.webp",
            //     modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/TableAndParasol.usdz"
            // },
            {
                id: 8,
                title: 'Taps',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/taps.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Taps.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/taps.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Taps.usdz",
                hotspotPos: "-0.007481181971306483m 0.855704794336456m 0.18873679624392917m",
                hotspotNorm: "-0.057727503811350424m -0.0019234496356118596m 0.9983305242479616m",
                hotspotText: "Water efficient design"
            },
            {
                id: 9,
                title: 'Dining area stool',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/diningareastool.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Dining area stool.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/diningareastool.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Dining area stool.usdz",
                hotspotPos: "0.05195591934996494m 0.41274617747637893m 0.1392114526747701m",
                hotspotNorm: "0.38381100210001023m 0.4222816978410371m 0.8211986862723766m",
                hotspotText: "PVC free fabric can be recycled. Manufactured in the UK from low carbon steel. Fully recyclable."
            },
            {
                id: 10,
                title: 'Booth bench',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/boothbench.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Booth Bench.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/boothbench.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Booth Bench.usdz",
                hotspotPos: "-0.1955000549514112m 0.6656648499444997m -0.2909014808521145m",
                hotspotNorm: "0.9832294111403774m 0.18237303821712947m -1.7685628662821652e-8m",
                hotspotText: "Manufactured in the UK from sustainably sourced ply wood. Print applied directly to wood with solvent-free ink.",
                isAtSauchiehallStreet: true,
            },
            // {
            //     id: 2,
            //     title: 'High chairs',
            //     image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/highchair.webp",
            //     modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/HighChair.glb",
            //     modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/highchair.webp",
            //     modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/HighChair.usdz",
                    // hotspotPos: "1.098064919540045m 0.8632354851254866m 0.08996960660639974m",
                    // hotspotNorm: "-8.923815035157655e-8m 0m 0.9999999999999961m",
                    // hotspotText: "Made from 100% PVC free material and fully recyclable."
            // },
            {
                id: 11,
                title: 'Ceiling tiles',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/ceilingtile.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/CeilingTile.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/ceilingtile.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/CeilingTile.usdz",
                hotspotPos: "-0.22998229423394087m 0.9631190186481838m 0.005323455668985844m",
                hotspotNorm: "0m 0m 1m",
                hotspotText: "Cradle to CradleTM certified. Returned to manufacturer at end of life and made into new product.",
                isAtSauchiehallStreet: true,
            },
            {
                id: 12,
                title: 'Internal cladding',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/internalwallcladding.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/InternalWallCladding.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/internalwallcladding.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/InternalWallCladding.usdz",
                hotspotPos: "-0.386035674186206m 0.9112486060440197m 0.015272794291376846m",
                hotspotNorm: "0m 0m 1m",
                hotspotText: "Made from sustainably sourced plywood of European origin. Fully recyclable.",
                isAtSauchiehallStreet: true,
            },
            {
                id: 13,
                title: 'Recycling units',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/recycleunit.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/RecycleUnit.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/recycleunit.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/RecycleUnit.usdz",
                hotspotPos: "-0.2829876665835642m 0.7424362567659584m 0.2733627364013458m",
                hotspotNorm: "1.8298465065094044e-8m -5.3212484893773664e-8m 0.9999999999999984m",
                hotspotText: "Made from AST timber product that can be shredded, recycled and repurposed into a number of other products.",
                isAtSauchiehallStreet: true,
            },
            // {
            //     id: 6,
            //     title: 'Soap dispenser',
            //     image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/soapdispenser.webp",
            //     modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/SoapDispenser.glb",
            //     modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/soapdispenser.webp",
            //     modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/SoapDispenser.usdz",
            //     hotspotPos: "0.02855663041227765m 0.04636599898473437m 0.09503303988065392m",
            //     hotspotNorm: "-1.3380345601783874e-7m -0.12006615878804434m 0.9927658926020103m",
            //     hotspotText: "Contact-free dispensers."
            // },
            {
                id: 14,
                title: 'Wall art',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/wallart.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/WallArt.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/wallart.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/WallArt.usdz",
                hotspotPos: "0.3142619177369497m 1.1712811488470476m -0.0031662718646547528m",
                hotspotNorm: "0m -5.3212484893773683e-8m 0.9999999999999987m",
                hotspotText: "Made from recycled polystyrene cups. Fully recyclable.",
                isAtSauchiehallStreet: true,
            },
            {
                id: 15,
                title: 'Table pedestal and top',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/tableandtop.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/TableAndTop.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/tableandtop.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/TableAndTop.usdz",
                hotspotPos: "0.11150680476498155m 0.7399999475423462m -0.12473929277687029m",
                hotspotNorm: "0m 1m 0m",
                hotspotText: "Made from sustainably sourced plywood of European origin. Fully recyclable.",
                hotspotPos2: "0.09319079387219209m 0.005748544758207297m 0.10555543533716188m",
                hotspotNorm2: "0m 1m 0m",
                hotspotText2: "Manufactured in the UK from low carbon steel. Fully recyclable.",
                isAtSauchiehallStreet: true,
            },
            {
                id: 16,
                title: 'Wall lettering',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/walllettering.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Wall Lettering.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/walllettering.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Wall Lettering.usdz",
                hotspotPos: "0.13691032326432195m 0.8844796418384168m 0.031099523119422122m",
                hotspotNorm: "-7.255684478452971e-9m -2.1557151198860132e-7m 0.9999999999999767m",
                hotspotText: "Made from recyclable acrylic backing and our own coffee bean waste.",
                isAtSauchiehallStreet: true,
            },
            {
                id: 17,
                title: 'Signage',
                image: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/signage.webp",
                modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Signage.glb",
                modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/signage.webp",
                modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Signage.usdz",
                hotspotPos: "-0.38835729048935885m 0.41277266354197073m 0.006199999712407589m",
                hotspotNorm: "0m 0m 1m",
                hotspotText: "Made from recycled materials. Fully recyclable.",
                isAtSauchiehallStreet: true,
            }
            // {
            //     id: 10,
            //     title: 'Paving',
            //     image: tileRenderImage,
            //     modelSrc: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/models/Paving.glb",
            //     modelPoster: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/Paving.webp",
            //     modelIos: "https://interactive.togetherwecanchangealot-mcdonalds.co.uk/model-viewer/images/Paving.usdz"
            // }
        ]
    }
]

export default tileData;